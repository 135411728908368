@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.75rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer components {
  /* CATALOG */
  .container-product-catalog {
    @apply max-w-[70rem] w-[90%] sm:w-[540px] md:w-[720px] lg:w-[960px] xl:w-[70rem] mx-auto;
  }

  .btn-primary {
    @apply py-2 px-5 bg-violet-500 text-white font-semibold rounded-full shadow-md hover:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-400 focus:ring-opacity-75;
  }

  /* Product Detail */
  .wrap-product {
    @apply py-[13px] px-[19.5px] !important;
  }

  .title-section-product {
    @apply font-[700];
    font-size: 18px;
  }


  /* GENERAL TEMPLATES */
  .reset-tailwind-override-htmltag {
    @apply [&_strong]:font-bold [&_ol]:list-decimal [&_ol]:pl-[40px] [&_ul]:list-disc
  }

  /* TYPOGRAPHY */
  .text-size-base {
    @apply text-[13px]
  }

  .mayar-text-description {
    @apply leading-[24.57px]
  }

  .basic-p-margin {
    margin-top: -5px;
    margin-bottom: 23px;
  }
}

:root {
  --font-nunito-sans: "Nunito Sans", system-ui, sans-serif;
  --font-open-sans: "Open Sans", system-ui, sans-serif;
}

* {
  /* font-size: 1; */
  /* background-color: #f8f9fa !important; */
  /* font-family: "Nunito Sans", sans-serif; */
}
body {
  /* font-size: 1rem;
  font-weight: 400; */
  /* color: #212529; */
  /* text-align: left; */
  /* background-color: #fff; */

  /* COMMENT THIS DUE TO ADAPT NEW UI */
  /* line-height: 1.2;
  font-size: 13px; */
}

/* p {
  font-size: 13px;
} */

/* :root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

.form-control {
  min-height: 36px;
  padding: 7.5px 17px 9px;
  background-color: #fbfcfc;
  border-color: #e6ecf0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  /* padding: 0.5rem 1rem; */
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
}

.is-invalid {
  background-color: #fef9fa;
  border-color: rgba(220, 53, 69, 0.25);
}

.text-secondary {
  color: #6c757d;
}

.text-success {
  color: #28a745;
}

.btn-lg {
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.white-space-enabled {
  white-space: pre-line;
}

.text-primary {
  color: #0d6efd;
}

.rui-main {
  overflow: hidden;
}

.rui-sign-form {
  max-width: 380px;
  padding: 30px;
}

.rui-sign-form-cloud {
  max-width: 400px;
  /* padding: 40px; */
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

body {
  color: #4b515b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #393f49;
}

@keyframes stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}

.stripe-pattern {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
}

.animate-stripes {
  animation: stripes 1s linear infinite;
}

.round-primary {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  -webkit-clip-path: circle();
  clip-path: circle();
}

/* rich text editor css */
.desc-editor h1,
.desc-editor h2,
.desc-editor h3,
.desc-editor h4,
.desc-editor h5,
.desc-editor h6 {
  /* margin-top: 1em; */
  margin-bottom: 1em;
  font-size: revert;
}

.desc-editor p {
  /* margin-top: 1em; */
  margin-bottom: 1em;
}

.desc-editor ul,
.desc-editor ol {
  padding-left: 1.5em;
  /* margin-top: 1em; */
  margin-bottom: 1em;
}

.desc-editor blockquote {
  margin-left: 1.5em;
  margin-right: 1.5em;
  padding-left: 1em;
  border-left: 4px solid #ccc;
  font-style: italic;
}

.desc-editor img {
  max-width: 100%;
  height: auto;
}

.desc-editor a {
  color: #007bff;
  text-decoration: none;
}
  

#nprogress .bar {
  background: #007bff !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #007bff, 0 0 5px #007bff !important;
}

#nprogress .spinner-icon {
  border-top-color: #007bff !important;
  border-left-color: #007bff !important;
}